<template>
  <v-container>
    <v-card elevation="0" class="pa-4 back-color">
      <v-card-title class="h5 pb-1">
        <v-icon left large color="warning">mdi-alert</v-icon>
        {{ `${$ml.get("suspended_account")}: ${mainDomain}` }}
      </v-card-title>
      <p class="pl-4" v-html="$ml.get('suspended_alert')"></p>
      <v-divider></v-divider>
      <v-card-title class="h6 font-weight-medium mt-2 pb-2">{{
        $ml.get("plan_details")
      }}</v-card-title>
      <p class="mb-1">
        <span class="font-weight-medium pl-4">{{ $ml.get("plan") }}:</span>
        {{ planName }}
      </p>
      <p>
        <span class="font-weight-medium pl-4"
          >{{ $ml.get("plan_payment") }}:</span
        >
        {{ $ml.get(subscriptionType) }}
      </p>
      <v-card-title class="h6 font-weight-medium pb-1">{{
        $ml.get("contact")
      }}</v-card-title>
      <p class="font-weight-regular pl-4">
        {{ $ml.get("contact_for_resolution") }}:
      </p>
      <p class="mb-1">
        <span class="font-weight-medium pl-4">Email:</span>
        {{ emails.financial }}
      </p>
      <p>
        <span class="font-weight-medium pl-4">{{ $ml.get("phone") }}:</span>
        +55 (48) 4062-1323
      </p>
      <p class="pl-4 pt-4" v-html="$ml.get('check_payment')"></p>

      <span class="pl-4 mt-6 text-caption">
        * {{ $ml.get("check_payment_mistake") }}
      </span>
    </v-card>
  </v-container>
</template>
<script>
import { emails } from "@/helpers/variables";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Suspended",
  data() {
    return {
      emails,
    };
  },
  computed: {
    ...mapGetters(["companyInSuite"]),
    planName() {
      return (
        (this.companyInSuite &&
          this.companyInSuite.current_plan &&
          this.companyInSuite.current_plan.plan &&
          this.companyInSuite.current_plan.plan.name) ||
        ""
      );
    },
    subscriptionType() {
      return (
        (this.companyInSuite &&
          this.companyInSuite.current_plan &&
          this.companyInSuite.current_plan.subscription_type &&
          this.companyInSuite.current_plan.subscription_type.toLowerCase()) ||
        ""
      );
    },
    mainDomain() {
      return (this.companyInSuite && this.companyInSuite.main_domain) || "";
    },
  },
  watch: {
    companyInSuite() {
      if (this.companyInSuite && !this.companyInSuite.suspended) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    ...mapActions(["getCompanyInSuite"]),
  },
  async beforeMount() {
    if (!this.companyInSuite) {
      await this.getCompanyInSuite();
    }
  },
};
</script>
<style lang="scss" scoped>
.back-color {
  background-color: #f5f5f5;
}
</style>
